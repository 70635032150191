import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Profile', url: '/profile', icon: 'person' },
    { title: 'Edit Profile', url: '/edit-profile', icon: 'create' },
    { title: 'Account', url: '/account', icon: 'key' }
  ];
  constructor(private router: Router, private zone: NgZone) {
    this.initApp();
  }

  initApp(){ // https://capacitorjs.com/docs/guides/deep-links
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('.com').pop();
        if (slug){
          this.router.navigateByUrl(slug);
        }
        // if no match do nothing - regular routing logic takes over
      });
    });
  }

}
